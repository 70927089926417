.circleOverlay {
  background: rgba(32,43,56,0.40);
  overflow: hidden;
  height: 100%;
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: 100%;
}

.imageContainer {
  position: relative;
  height: 100%;
  width: 200px;
  margin-bottom: 20px;
  margin-top: 52px;
}


.circleProfileAbout {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  margin-top: 32px;
  /* border-radius: 100%; */
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-repeat: no-repeat;
}

@media (max-width: 420px) {
  .circleProfileAbout, .circleOverlay {
    width: 130px;
    height: 130px;
    margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
  }
}

.gr_custom_container_1647803031 {
  /* customize your Goodreads widget container here*/
  border-radius:10px;
  padding: 10px 5px 10px 5px;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
  color: #000000;
  width: 300px
  }
  .gr_custom_header_1647803031 {
  /* customize your Goodreads header here*/
  border-bottom: 1px solid gray;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  }
  .gr_custom_each_container_1647803031 {
  /* customize each individual book container here */
  width: 100%;
  clear: both;
  margin-bottom: 10px;
  overflow: auto;
  padding-bottom: 4px;
  border-bottom: 1px solid #aaa;
  }
  .gr_custom_book_container_1647803031 {
  /* customize your book covers here */
  overflow: hidden;
  height: 60px;
  float: left;
  margin-right: 4px;
  width: 39px;
  }
  .gr_custom_author_1647803031 {
  /* customize your author names here */
  font-size: 10px;
  }
  .gr_custom_tags_1647803031 {
  /* customize your tags here */
  font-size: 10px;
  color: gray;
  }
  .gr_custom_rating_1647803031 {
  /* customize your rating stars here */
  float: right;
  }

  .gr_custom_title_1647803031 {
    font-size: 14px;
  }

  .staticStars  { 
    display: none;
  }
  
  .gr_custom_tags_1647803031 {
    display: none;
  }