.App {
  text-align: center;
}

.main {
  width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.linkedinLogo {
  display: block;
}

.j-btn {
  background-color: #142333;
  border-color: #142333;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: 0.05rem solid #fff;
}

/*
 * Base structure
 */

html,
body {
  height: 100%;
  background-color: #001c31;
  font-family: "Josefin Sans", sans-serif;
}

.Home {
  overflow-x: hidden;
}

.Home-cta {
  text-align: center;
}

.Home-footer {
  text-align: center;
}

.Home-footer form {
  width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Home-footer form label {
  width: 100%;
}

.Home-footer form button {
  width: 100%;
}

.Home-intro-text {
  text-align: center;
}

.Home-json-cv {
  text-align: center;
}

.Home-work {
  text-align: center;
}

.Home-work .row {
  margin: 0;
  padding: 0;
}

.bordBox {
  border-right: 1px solid #222e59;
}

.bordBox > h3,
.bordBox > p,
.finalBordBox > h3,
.finalBordBox > p {
  text-align: center;
}

.bordBox label,
.finalBordBox label {
  text-align: center;
  display: block;
}

.servBox {
  height: 180px;
  border-radius: 15px;
  background-color: white;
  padding: 18px;
  display: flex;
  flex-direction: column;
}

.servBox a,
.servBox img {
  width: auto;
  height: 150px;
  object-fit: contain;
  padding: 0 15px;
  max-width: 320px;
  margin: 0 auto;
}

.servBox > a {
  display: contents;
}

.container-fluid {
  padding: 0 !important;
}
body {
  display: -ms-flexbox;
  display: flex;
  color: #fff;
}

.skills {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: none;
  text-align: center;
  padding: 0;
}

main {
  overflow-x: hidden;
}

.nav-logo {
  height: 35px;
  width: 25px;
  float: left;
}

.copy > p {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.circleProfile {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  margin-top: 32px;
  /* border-radius: 100%; */
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-repeat: no-repeat;
}

/*
 * Header
 */
.masthead {
  margin-bottom: 2rem;
}

.masthead-brand {
  margin-bottom: 0;
}

.nav-masthead .nav-link {
  padding: 0.25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.box {
  background-color: white;
  border-radius: 15px;
  color: #001c31;
}

#homeWeb {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.social-icons > div > a > img {
  width: 50%;
}

.quote > h4 {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.icon > img {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.whiteSection {
  background-color: #222e59;
  display: block;
  color: white;
}

@media (max-width: 768px) {
  .bordBox {
    border-right: 0px;
    border-bottom: 1px solid #222e59;
  }

  .circleProfile {
    width: 220px;
    height: 220px;
  }

  .servBox {
    padding: 5px;
    border-radius: 0;
  }

  .servBox > img {
    width: 98%;
    padding: 0;
    max-width: 250px;
    margin: 0 auto;
  }

  h1 {
    font-size: 1.75rem;
  }
}

@media (max-width: 420px) {
  .circleProfile {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 500px) {
  .social-icons > div > a > img {
    width: 150%;
  }
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

@media (min-width: 768px) {
  .servBox a,
  .servBox img {
    width: auto;
    height: 150px;
    object-fit: contain;
    padding: 0 15px;
    max-width: 190px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {
  .servBox a,
  .servBox img {
    max-width: 320px;
  }
}

/*
 * Cover
 */
.cover {
  padding: 0 1.5rem;
}
.cover .btn-lg {
  padding: 0.75rem 1.25rem;
  font-weight: 700;
}

/*
 * Footer
 */
.mastfoot {
  color: rgba(255, 255, 255, 0.5);
}
