.Header {
    background: #001c31;
    color: white;
    max-width: 1400px;

    .active {
        text-decoration: underline;
    }

    nav {
        margin-left: auto;
        a {
            color: white;
        }
    }
}